import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {MenuItem} from "primeng/api";
import {HeaderService} from "./header.service";



export interface HeaderBadgeCountData {
    module: string,
    badges: {
        name: string,
        count: number
    }[]
}


@Injectable({providedIn: 'root'})
export class HeaderBadgeService {


    newHeaderBadgeCountEvent = new EventEmitter<HeaderBadgeCountData[]>();
    headerMenuItemsWithoutBadges: MenuItem[];

    constructor(
        private http:HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private headerService: HeaderService
    ) {

    }

    getHeaderBadgeCounts() {

        const module = this.router.url.split('/')[3];

        console.log('GET BADGE COUNTS')

        console.log(module)

        if(module) {

            const apiPath = 'v1/header-component'
            const module = this.router.url.split('/')[3];
            const endpoint = environment.apiUrl + apiPath
            const params = new HttpParams()
                .set('activeEnterpriseId', encodeURIComponent(this.router.url.split('/')[1]));


            this.http.get(endpoint, {params: params, withCredentials: true}).subscribe((res:HeaderBadgeCountData[]) => {
                this.newHeaderBadgeCountEvent.emit(res);
            });

        }

    }

    mapHeaderBadgeCountsToMenuItems(headerBadgeCountData:HeaderBadgeCountData[]):MenuItem[] {

        const headerMenuItems = [...this.headerMenuItemsWithoutBadges];



        const appointmentCount = headerBadgeCountData[0].badges.find(badge => badge.name === 'appointments')?.count;
        const mailboxMessagesCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'mailboxMessages')?.count;
        const writingsCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'writings')?.count;
        const writingDeliveriesCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'writingDeliveries')?.count;
        const invoicesCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'invoices')?.count;
        const otherAccountingDocumentsCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'otherAccountingDocuments')?.count;
        const payrollsCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'payrollsCount')?.count;
        const employeePayrollsCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'employeePayrollsCount')?.count;
        const payrollDocumentsCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'payrollDocumentsCount')?.count;
        const bankAccountsCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'bankAccounts')?.count;
        const bankTransactionsCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'bankTransactions')?.count;
        const paymentClaimsCount = headerBadgeCountData[1].badges.find(badge => badge.name === 'paymentClaims')?.count;


        console.log('__APPOINTMENT_COUNT__')
        console.log(appointmentCount)
        if(appointmentCount > 0) {
            this.headerService.badgeCountChangeEventEmitter.emit(
                {
                    appointmentBadgeCount: appointmentCount
                }
            );
        }



        if (this.router.url.split('/')[3] === 'office') {

            // Mailbox Messages#
            if (mailboxMessagesCount > 0) {
                headerMenuItems[2].badge = mailboxMessagesCount.toString();
                headerMenuItems[2].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[2].badge;
                delete headerMenuItems[2].badgeStyleClass;
            }

            // Writings
            if (writingsCount > 0) {
                headerMenuItems[3].items[0].badge = writingsCount.toString();
                headerMenuItems[3].items[0].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[3].items[0].badge;
                delete headerMenuItems[3].items[0].badgeStyleClass;
            }
            if (writingDeliveriesCount > 0) {
                headerMenuItems[3].items[2].badge = writingDeliveriesCount.toString();
                headerMenuItems[3].items[2].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[3].items[2].badge;
                delete headerMenuItems[3].items[2].badgeStyleClass;
            }
            if ((writingsCount + writingDeliveriesCount) > 0) {
                headerMenuItems[3].badge = (writingsCount + writingDeliveriesCount).toString();
                headerMenuItems[3].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[3].badge;
                delete headerMenuItems[3].badgeStyleClass;
            }

            //// Accounting

            // DOCUMENTS
            // Invoices
            if (invoicesCount > 0) {
                headerMenuItems[4].items[0].items[0].badge = invoicesCount.toString();
                headerMenuItems[4].items[0].items[0].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[0].items[0].badge;
                delete headerMenuItems[4].items[0].items[0].badgeStyleClass;
            }
            // OtherAccountingDocuments
            if (otherAccountingDocumentsCount > 0) {
                headerMenuItems[4].items[0].items[2].badge = otherAccountingDocumentsCount.toString();
                headerMenuItems[4].items[0].items[2].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[0].items[2].badge;
                delete headerMenuItems[4].items[0].items[2].badgeStyleClass;
            }
            const documentsCount = invoicesCount + otherAccountingDocumentsCount;
            if(documentsCount > 0) {
                headerMenuItems[4].items[0].badge = documentsCount.toString();
                headerMenuItems[4].items[0].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[0].badge;
                delete headerMenuItems[4].items[0].badgeStyleClass;
            }
            //


            // PAYROLLS TAB
            // Payrolls
            if (payrollsCount > 0) {
                headerMenuItems[4].items[1].items[0].badge = payrollsCount.toString();
                headerMenuItems[4].items[1].items[0].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[1].items[0].badge;
                delete headerMenuItems[4].items[1].items[0].badgeStyleClass;
            }
            // EmployeePayrolls
            if (employeePayrollsCount > 0) {
                headerMenuItems[4].items[1].items[2].badge = employeePayrollsCount.toString();
                headerMenuItems[4].items[1].items[2].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[1].items[2].badge;
                delete headerMenuItems[4].items[1].items[2].badgeStyleClass;
            }
            // PayrollDocuments
            if (payrollDocumentsCount > 0) {
                headerMenuItems[4].items[1].items[3].badge = payrollDocumentsCount.toString();
                headerMenuItems[4].items[1].items[3].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[1].items[3].badge;
                delete headerMenuItems[4].items[1].items[3].badgeStyleClass;
            }
            const payrollsTabCount = payrollsCount + employeePayrollsCount + payrollDocumentsCount;
            if (payrollsTabCount > 0) {
                headerMenuItems[4].items[1].badge = payrollsTabCount.toString();
                headerMenuItems[4].items[1].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[1].badge;
                delete headerMenuItems[4].items[1].badgeStyleClass;
            }


            // BANKING
            // BankAccounts
            if (bankAccountsCount > 0) {
                headerMenuItems[4].items[2].items[0].badge = bankAccountsCount.toString();
                headerMenuItems[4].items[2].items[0].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[2].items[0].badge;
                delete headerMenuItems[4].items[2].items[0].badgeStyleClass;
            }
            // BankTransactions
            if (bankTransactionsCount > 0) {
                headerMenuItems[4].items[2].items[1].badge = bankTransactionsCount.toString();
                headerMenuItems[4].items[2].items[1].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[2].items[1].badge;
                delete headerMenuItems[4].items[2].items[1].badgeStyleClass;
            }
            const bankingCount = bankAccountsCount + bankTransactionsCount;
            if (bankingCount > 0) {
                headerMenuItems[4].items[2].badge = bankingCount.toString();
                headerMenuItems[4].items[2].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[2].badge;
                delete headerMenuItems[4].items[2].badgeStyleClass;
            }
            //


            // PaymentClaims
            if (paymentClaimsCount > 0) {
                headerMenuItems[4].items[3].items[0].badge = paymentClaimsCount.toString();
                headerMenuItems[4].items[3].items[0].badgeStyleClass = 'badge-secondary';
                headerMenuItems[4].items[3].badge = paymentClaimsCount.toString();
                headerMenuItems[4].items[3].badgeStyleClass = 'badge-secondary';
            } else {
                delete headerMenuItems[4].items[3].items[0].badge;
                delete headerMenuItems[4].items[3].items[0].badgeStyleClass;
                delete headerMenuItems[4].items[3].badge;
                delete headerMenuItems[4].items[3].badgeStyleClass;
            }

            if ((invoicesCount + bankTransactionsCount + paymentClaimsCount) > 0) {
                headerMenuItems[4].badge = (invoicesCount + bankTransactionsCount + paymentClaimsCount).toString();
                headerMenuItems[4].badgeStyleClass = 'badge-secondary';
            }


        }

        const officeTasksCount = mailboxMessagesCount + writingsCount + writingDeliveriesCount + invoicesCount + otherAccountingDocumentsCount + bankTransactionsCount + paymentClaimsCount;


        if (officeTasksCount > 0) {
            headerMenuItems[0].items[0].badge = officeTasksCount.toString();
            headerMenuItems[0].items[0].badgeStyleClass = 'badge-secondary';
        }






        /// RENTAL

        const rentalExposeListingsCount = headerBadgeCountData[3].badges.find(badge => badge.name === 'rentalExposeListings')?.count;


        if (this.router.url.split('/')[3] === 'rentals') {
            if (rentalExposeListingsCount > 0) {
                headerMenuItems[2].badge = rentalExposeListingsCount.toString();
                headerMenuItems[2].badgeStyleClass = 'badge-secondary';
                headerMenuItems[2].items[2].badge = rentalExposeListingsCount.toString();
                headerMenuItems[2].items[2].badgeStyleClass = 'badge-secondary';
                headerMenuItems[2].items[2].items[0].badge = rentalExposeListingsCount.toString();
                headerMenuItems[2].items[2].items[0].badgeStyleClass = 'badge-secondary';
            } else {
            }
        }

        const rentalTasksCount = rentalExposeListingsCount;

        if(rentalTasksCount > 0) {
            headerMenuItems[0].items[3].badge = rentalTasksCount.toString();
            headerMenuItems[0].items[3].badgeStyleClass = 'badge-secondary';
        }




        //headerMenuItems[0].items[1].badge = '99';
        //headerMenuItems[0].items[2].badge = '99';
        //headerMenuItems[0].items[3].badge = '99';
        //headerMenuItems[0].items[4].badge = '99';


        return headerMenuItems;
    }



}
