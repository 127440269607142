import {TranslateService} from "@ngx-translate/core";

export class MenuSalesModel {

    menuItems:any;

    constructor(

        private translate: TranslateService,
        private currentRoute?: string,
    ) {

        this.menuItems = [
            {
                label: 'Exposes',
                icon: 'mi mi-filter',
                routerLinkActiveOptions: {exact: true},
                items: [
                    {
                        label: 'All Exposes',
                        icon: 'mi mi-list',
                        routerLink: [this.currentRoute, 'rentals', 'exposes', 'list'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Listings',
                        icon: 'mi mi-storefront',
                        routerLinkActiveOptions: {exact: true},
                        items: [
                            {
                                label: 'All Listings',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'exposes', 'listings'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Publications',
                                icon: 'mi mi-checklist',
                                routerLink: [this.currentRoute, 'rentals', 'exposes', 'listing-publications'],
                            },
                        ]
                    },
                ]
            },
            {
                label: 'Documents',
                icon: 'mi mi-snippet-folder',
                routerLink: [this.currentRoute, 'sales', 'documents'],
                routerLinkActiveOptions: {exact: true}
            },
            {
                label: 'Requests',
                icon: 'mi mi-assignment-ind',
                routerLink: [this.currentRoute, 'sales', '01-requests'],
                routerLinkActiveOptions: {exact: true},
                items: [
                    {
                        label: 'All Requests',
                        icon: 'pi pi-list',
                    },
                    {
                      separator: true
                    },
                    {
                        label: '1. New Requests',
                    },
                    {
                        label: '2. Self-Disclosures',
                    },
                    {
                        label: '3. Viewing-Appointments',
                        items: [
                            {
                                label: 'All Viewing-Appointments',
                                icon: 'pi pi-list',
                            },
                            {
                              separator: true
                            },
                            {
                                label: '1. For Scheduling',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'viewing-appointments'],
                                queryParams: {status: 'FOR_SCHEDULING'},
                            },
                            {
                                label: '2. Scheduled',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'viewing-appointments'],
                                queryParams: {status: 'SCHEDULED'},
                            },
                            {
                                label: '3. Past Appointments for Examination',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'viewing-appointments'],
                                queryParams: {status: 'PAST'},
                            },
                            {separator: true},
                            {
                                label: 'Missed Appointments',
                                routerLink: [this.currentRoute, 'move-outs', 'missed-appointments'],
                            },
                            {
                                label: 'Rescheduled Appointments',
                                routerLink: [this.currentRoute, 'move-outs', 'rescheduled-appointments'],
                            }
                        ]
                    },
                    {
                        label: '4. Offers & Negotiations',
                    },
                    {
                        label: '5. Contract-Preparations',
                    },
                    {
                      separator: true
                    },
                    {
                        label: 'Declines',
                        icon: 'mi mi-disabled-by-default',
                        items: [
                            {
                                label: 'For Review',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'declines'],
                                queryParams: {status: 'FOR_REVIEW'},
                            },
                            {
                                separator: true,
                            },
                            {
                                label: 'All Declines',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'declines'],
                            },
                        ]
                    }
                ]
            }
        ]

    }
}




