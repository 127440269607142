import {TranslateService} from "@ngx-translate/core";

export class MenuUserModel {

    menuItems: any;

    constructor(

        private translate: TranslateService,
        private currentRoute?: string,
    ) {

        this.menuItems = [
            {
                label: 'Einstellungen',
                icon: 'pi pi-user-edit',
                routerLink: [this.currentRoute, 'user', 'settings'],
                routerLinkActiveOptions: {exact: true}
            },
            {
                label: 'Hilfe & FAQ',
                icon: 'pi pi-question-circle',
                routerLink: [this.currentRoute, 'help'],
                routerLinkActiveOptions: {exact: true}
            },
        ]


    }


}
