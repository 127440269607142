import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthComponent} from "./system/auth/auth.component";
import {AuthGuard} from "./system/auth/auth.guard";
import {EnterpriseCountryGuard} from "./shared/guards/enterprise-country.guard";
import {ErrorComponent} from "./system/error/error.component";
import {ChooseEnterpriseComponent} from "./system/auth/choose-enterprise/choose-enterprise.component";

const routes: Routes = [
    { path: '', redirectTo: 'choose-enterprise', pathMatch: "full"},
    { path: 'auth', component: AuthComponent },
    { path: 'choose-enterprise', canActivate: [AuthGuard], component: ChooseEnterpriseComponent},
    { path: ':enterpriseId', canActivate: [AuthGuard], children: [
            { path: 'error', component: ErrorComponent},
            { path: ':countryCode', canActivate: [EnterpriseCountryGuard], children: [
                { path: 'office', loadChildren: () => import('./1-office/office.module').then(m => m.OfficeModule)},
                { path: 'rentals', loadChildren: () => import('./rentals/rentals.module').then(m => m.RentalsModule) },
                { path: 'properties', loadChildren: () => import('./2-properties/properties.module').then(m => m.PropertiesModule) },
                { path: 'management', loadChildren: () => import('./management/management.module').then(m => m.ManagementModule) },
                { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule) },
                { path: 'user', loadChildren: () => import('./system/user/user.module').then(m => m.UserModule) },
                { path: 'master', children: [
                    {path: 'settings', loadChildren: () => import('./system/master/settings/settings.module').then(m => m.SettingsModule)},
                    {path: 'users', loadChildren: () => import('./system/master/users/users.module').then(m => m.UsersModule)},
              ]},
            { path: 'legal', loadChildren: () => import('./system/legal/legal.module').then(m => m.LegalModule) },
        ]},
    ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
