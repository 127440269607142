<ng-container *ngIf="this.isAuthenticated">

    <div class="header flex flex-nowrap">
        <div #menubar1 class="flex-grow-1">
            <p-menubar [autoDisplay]="true" [model]="headerMenuItems" *ngIf="isAuthenticated" #menubar>

                <ng-template pTemplate="end">
                    <div class="flex" #menubarEnd>

                        <div class="switch-enterprise-button flex flex-nowrap align-items-center cursor-pointer" (click)="onChooseEnterprise()">
                            <div class="profile-wrapper">
                                <button type="button" pButton icon="mi mi-view-list" label=""
                                        class="p-button p-button-rounded p-button-text p-button-secondary"></button>
                            </div>
                            <div>{{ activeEnterpriseName }}</div>
                        </div>

                        <div class="profile-wrapper">
                            <div class="country-button">
                                <button type="button" pButton label=""
                                        class="p-button p-button-rounded p-button-text p-button-secondary"><i
                                        class="fi fi-de"></i></button>
                            </div>
                        </div>

                        <div class="profile-wrapper">
                            <div class="appointments-button">
                                <button type="button" pButton [icon]="PrimeIcons.CALENDAR" label=""
                                        (click)="navigate(['user', 'appointments'])"
                                        class="p-button p-button-rounded p-button-text p-button-secondary">
                                    <p-badge [value]="appointmentBadgeCount" severity="info"
                                             *ngIf="appointmentBadgeCount"></p-badge>
                                </button>
                            </div>
                        </div>

                        <div class="profile-wrapper">
                            <div class="profile-button">
                                <p-menu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu
                                        [popup]="true"
                                        [model]="profileItems"></p-menu>
                                <button type="button" pButton [icon]="PrimeIcons.USER" label=""
                                        (click)="menu.toggle($event)"
                                        class="p-button p-button-rounded p-button-raised"></button>
                            </div>
                        </div>

                    </div>
                </ng-template>
            </p-menubar>
        </div>
        <div>
            <p-menubar *ngIf="!isAuthenticated">
                <div class="profile-button-lock">
                    <button
                            type="button"
                            pButton [icon]="PrimeIcons.LOCK"
                            label=""
                            class="p-button-danger p-button-rounded"
                            routerLink="/auth">
                    </button>
                </div>
            </p-menubar>
        </div>
    </div>

</ng-container>
