import {TranslateService} from "@ngx-translate/core";

export class MenuManagementModel {

    menuItems: any;

    constructor(
        private translate: TranslateService,
        private currentRoute?: string,
    ) {

        this.menuItems = [
            {
                label: 'Utility Bills',
                icon: 'mi mi-receipt-long',
                items: [
                    {
                        label: 'All Utility Bills',
                        icon: 'pi pi-list',
                        routerLink: [this.currentRoute, 'management', 'utility-billings'],
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Unit-Billings',
                        icon: 'mi mi-receipt-long',
                        routerLink: [this.currentRoute, 'management', 'utilities', 'unit-billings'],
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Cost-list',
                        icon: 'mi mi-receipt-long',
                        routerLink: [this.currentRoute, 'management', 'utilities', 'cost-list'],
                    },
                ]
            },
            {
                label: 'Maintenance',
                icon: 'pi pi-list',
                styleClass: 'menuManagementMaintenance',
                items: [
                    {
                        label: 'Planned Tasks',
                        badge: '2',
                        routerLink: [this.currentRoute, 'management', 'maintenance', 'planned-tasks'],
                    },
                    {
                        label: 'Tasks',
                        badge: '2',
                        routerLink: [this.currentRoute, 'management', 'maintenance', 'tasks'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Craftsman Jobs',
                        routerLink: [this.currentRoute, 'management', 'house-rules', 'tasks'],
                    },
                ]
            },
            {
                label: 'Repairs',
                icon: 'pi pi-list',
                styleClass: 'menuManagementRepairs',
                items: [
                    {
                        label: 'Reports',
                        routerLink: [this.currentRoute, 'management', 'repairs', 'reports'],
                    },
                    {
                        label: 'Tasks',
                        routerLink: [this.currentRoute, 'management', 'repairs', 'tasks'],
                    },
                    {
                      separator: true
                    },
                    {
                        label: 'Craftsman Jobs',
                        routerLink: [this.currentRoute, 'management', 'house-rules', 'tasks'],
                    },
                    {
                        label: 'Insurance Reports',
                        routerLink: [this.currentRoute, 'management', 'house-rules', 'tasks'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Reparation Confirmations',
                        routerLink: [this.currentRoute, 'management', 'house-rules', 'tasks'],
                    },
                ]
            },
            {
                label: 'House-Rules',
                icon: 'pi pi-list',
                styleClass: 'menuManagementHouseRules',
                items: [
                    {
                        label: 'Reports',
                        routerLink: [this.currentRoute, 'management', 'house-rules', 'reports'],
                    },
                    {
                        label: 'Tasks',
                        routerLink: [this.currentRoute, 'management', 'house-rules', 'tasks'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Authority Reports',
                        routerLink: [this.currentRoute, 'management', 'house-rules', 'tasks'],
                    },
                ]
            },
            {
                label: 'Neighbors',
                icon: 'mi mi-home-work',
                routerLink: [this.currentRoute, 'management', 'neighbors'],
            },
            {
                label: 'Caretakers',
                icon: 'pi pi-list',
                routerLink: [this.currentRoute, 'management', 'caretakers'],
                styleClass: 'menuManagementCaretakers',
                items: [
                    {
                        label: 'All Caretakers',
                        icon: 'pi pi-list',
                        routerLink: [this.currentRoute, 'management', 'caretakers'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Caretakers-Portal',
                        icon: 'pi pi-external-link',
                        styleClass: 'menuManagementCaretakerPortal',
                    },
                ]
            },

        ]

        this.translateMenuItems();
    }





    translateMenuItems() {
/*        this.menuItems[0].label = this.translate.instant(this.menuItems[0].label);
        this.menuItems[0].items[0].label = this.translate.instant(this.menuItems[0].items[0].label);
        this.menuItems[0].items[2].label = this.translate.instant(this.menuItems[0].items[2].label);
        this.menuItems[0].items[4].label = this.translate.instant(this.menuItems[0].items[4].label);
        this.menuItems[1].label = this.translate.instant(this.menuItems[1].label);
        this.menuItems[1].items[0].label = this.translate.instant(this.menuItems[1].items[0].label);
        this.menuItems[1].items[1].label = this.translate.instant(this.menuItems[1].items[1].label);
        this.menuItems[2].label = this.translate.instant(this.menuItems[2].label);
        this.menuItems[2].items[0].label = this.translate.instant(this.menuItems[2].items[0].label);
        this.menuItems[2].items[1].label = this.translate.instant(this.menuItems[2].items[1].label);
        this.menuItems[3].label = this.translate.instant(this.menuItems[3].label);
        this.menuItems[3].items[0].label = this.translate.instant(this.menuItems[3].items[0].label);
        this.menuItems[3].items[1].label = this.translate.instant(this.menuItems[3].items[1].label);
        this.menuItems[4].items[0].label = this.translate.instant(this.menuItems[4].items[0].label);*/
    }




}
