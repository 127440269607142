import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {BehaviorSubject, catchError, tap, throwError} from "rxjs";
import {User, UserEnterprise} from "./user.model";
import {Router} from "@angular/router";
import { environment } from '../../../environments/environment';
import {TranslateService} from "@ngx-translate/core";

export interface AuthResponseData {
    user: User,
    _access_token: string;
    _tokenExpirationTime: number;
    darkMode: boolean;
    enterprises: UserEnterprise[];
}

@Injectable({providedIn: 'root'})
export class AuthService {


    user = new BehaviorSubject<User>(null);

    private tokenExpirationTimer: any;

    constructor(private http: HttpClient,
                private router: Router,

    ) {
    }


    login(email: string, password: string) {
        return this.http.post<AuthResponseData>(
            environment.apiUrl + 'v1/login',
            {
                email: email.toLowerCase(),
                password: password,
            }, {withCredentials: true}
        ).pipe(catchError(this.errorHandler),
            tap(resData => {
                this.handleAuthentication(
                    resData.user.email,
                    resData.user.firstName,
                    resData.user.lastName,
                    resData._access_token,
                    +resData._tokenExpirationTime,
                    resData.user.darkMode,
                    resData.user.language,
                    resData.user.enterprises
                );
                this.redirectAfterLogin();
            })
        );
    }


    logout() {
        this.user.next(null); // Clear user data in the frontend
        this.router.navigate(['/auth']); // Redirect to authentication page
        this.http.get(environment.apiUrl + 'v1/authentication/logout', { withCredentials: true }).subscribe(
            () => {
                console.log('Logout successful');
            },
            (error) => {
                console.error('Logout failed', error);
            }
        );
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }
        this.tokenExpirationTimer = null;
    }


    private handleAuthentication(
        email: string,
        firstName,
        lastName,
        _access_token: string,
        _tokenExpirationTime: number,
        darkMode: boolean,
        language: string,
        enterprises: UserEnterprise[],
        ) {

        console.log('AUTH DARKMODE: ' + darkMode)

        const expirationDate = new Date(new Date().getTime() + _tokenExpirationTime);

        const user = new User(
            email,
            firstName,
            lastName,
            _access_token,
            expirationDate,
            darkMode,
            language,
            enterprises
        );

        this.autoLogout(_tokenExpirationTime);
        console.log('_________USER_________')
        console.log(user);
        this.user.next(user);

    }

    autoLogin() {
        this.http.get<AuthResponseData>(
            environment.apiUrl + 'v1/authentication/refresh', {withCredentials: true})
            .pipe(catchError(this.errorHandler),
                tap(resData => {
                    this.handleAuthentication(
                        resData.user.email,
                        resData.user.firstName,
                        resData.user.lastName,
                        resData._access_token,
                        resData._tokenExpirationTime,
                        resData.user.darkMode,
                        resData.user.language,
                        resData.user.enterprises
                    );
                    this.redirectAfterLogin();
                })
            ).subscribe();
    }

    autoLogout(expirationDuration: number) {
        this.tokenExpirationTimer = setTimeout(() => {
        }, expirationDuration);
    }


    private errorHandler(errorRes: HttpErrorResponse) {
        let errorMessage = 'An unknown error occured!';
        if (!errorRes.error || !errorRes.error.message) {
            return throwError(errorMessage);
        } else {
            switch (errorRes.error.message) {
                case 'EMAIL_NOT_FOUND':
                    errorMessage = 'T his email-component is not correct!';
                    break;
                case 'INVALID_PASSWORD':
                    errorMessage = 'This password is not correct!';
                    break;
            }
            return throwError(errorRes.error.message);
        }
    }


    private redirectAfterLogin() {
        const redirectUrl = localStorage.getItem('redirectUrl') || '/choose-enterprise';
        this.router.navigateByUrl(redirectUrl);
    }







}
