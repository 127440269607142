<div class="flex flex-column min-h-screen">

    <div class="content">
        <div *ngIf="isLoading" class="spinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                               fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="error" class="alert alert-danger">
            <a>{{ error }}</a>
        </div>
        <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!isLoading">


            <div class="surface-card p-4 shadow-2 border-round w-full">
                <div class="text-center">
                    <div class="text-900 text-3xl font-medium mb-3">Login</div>
                </div>
                <div>
                    <input id="email"
                           name="email"
                           type="text"
                           placeholder="E-Mail"
                           pInputText
                           class="w-full mb-3"
                           ngModel
                           required
                           email
                    />
                    <input id="password"
                           name="password"
                           type="password"
                           placeholder="Password"
                           pInputText
                           class="w-full mb-3"
                           ngModel
                           required
                           minlength="6"
                    />

                    <div class="align-items-center text-right justify-content-between mb-5">

                        <a class="font-medium no-underline text-color-secondary cursor-pointer mr-2">
                            Forgot password?</a>
                    </div>

                    <button
                            pButton
                            pRipple
                            [disabled]="!authForm.valid"
                            type="submit"
                            label="Sign In"
                            style="padding-left: -10px;"
                            class="w-full">
                    </button>
                </div>
            </div>

        </form>

    </div>

    <div class="flex flex-column" style="min-height: 100%">
        <div class="flex-grow-1"></div>
        <div class="flex-grow-0 footer">
            <div>
                <span>This website is SSL protected</span>
            </div>
        </div>
        <div class="flex-grow-1"></div>
    </div>

</div>
