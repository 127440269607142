import {TranslateService} from "@ngx-translate/core";
import {RentRequestStatus} from "../../../rentals/move-ins/01-requests/enums/rent-request-status.enum";
import {Router} from "@angular/router";

export class MenuRentalsModel {

    menuItems: any;

    constructor(
        private translate: TranslateService,
        private currentRoute?: string,
    ) {
        this.menuItems = [
            {
                label: this.translate.instant('Tenants'),
                icon: 'mi mi-diversity-1 ',
                routerLinkActiveOptions: {exact: true},
                items: [
                    {
                        label: this.translate.instant('Rental Status'),
                        icon: 'mi mi-apartment',
                        routerLink: [this.currentRoute, 'rentals', 'list'],
                    },
                    {
                        separator: true,
                    },
                    {
                        label: this.translate.instant('Contracts'),
                        icon: 'mi mi-description',
                        items: [
                            {
                                label: this.translate.instant('All Contracts'),
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'contracts'],
                            },
                            {
                                separator: true,
                            },
                            {
                                label: this.translate.instant('Contract Supplements'),
                                icon: 'mi mi-post-add',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'contract-supplements'],
                            },
                        ]
                    },
                    {
                        label: this.translate.instant('Residents'),
                        icon: 'mi mi-family-restroom',
                        items: [
                            {
                                label: this.translate.instant('All Residents'),
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'residents'],
                            },
                            {
                                separator: true,
                            },
                            {
                                label: this.translate.instant('Pets'),
                                icon: 'mi mi-pets',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'pets'],
                            },
                        ]
                    },
                    {
                        label: this.translate.instant('Rent'),
                        icon: 'mi mi-currency-exchange',
                        routerLink: [this.currentRoute, 'rentals', 'tenants', 'rent-reductions'],
                        items: [
                            {
                                label: this.translate.instant('Changes'),
                                icon: 'mi mi-change-circle',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'contract-rent-changes'],
                            },
                            {separator: true},
                            {
                                label: this.translate.instant('Increases'),
                                icon: 'mi mi-trending-up',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'rent-increases'],
                            },
                            {
                                label: this.translate.instant('Reductions'),
                                icon: 'mi mi-trending-down',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'rent-reductions'],
                            },
                        ]
                    },
                    {
                        label: this.translate.instant('Keys'),
                        icon: 'mi mi-key',
                        routerLink: [this.currentRoute, 'rentals', 'tenants', 'keys'],
                    },
                    {
                        label: this.translate.instant('Viewings'),
                        icon: 'mi mi-calendar_month',
                        routerLink: [this.currentRoute, 'rentals', 'tenants', 'viewing-appointments'],
                    },
                    {
                        label: this.translate.instant('Warnings'),
                        icon: 'mi mi-warning',
                        routerLink: [this.currentRoute, 'rentals', 'tenants', 'warnings'],
                    },
                    {
                        label: this.translate.instant('Reviews'),
                        icon: 'mi mi-reviews',
                        items: [
                            {
                                label: this.translate.instant('Tenant-Reviews'),
                                icon: 'mi mi-reviews',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'reviews'],
                            },
                            {
                                label: this.translate.instant('Landlord-Reviews'),
                                icon: 'mi mi-reviews',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'reviews'],
                            }
                        ]
                    },
                    {
                        separator: true
                    },
                    {
                        label: this.translate.instant('Tenant-Portal'),
                        styleClass: 'menuRentalMoveOuts',
                        icon: 'pi pi-external-link',
                    },
                ]
            },
            {
                label: this.translate.instant('Exposes'),
                icon: 'mi mi-filter',
                routerLinkActiveOptions: {exact: true},
                items: [
                    {
                        label: this.translate.instant('All Exposes'),
                        icon: 'mi mi-list',
                        routerLink: [this.currentRoute, 'rentals', 'exposes', 'list'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: this.translate.instant('Listings'),
                        icon: 'mi mi-storefront',
                        routerLinkActiveOptions: {exact: true},
                        items: [
                            {
                                label: this.translate.instant('All Listings'),
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'exposes', 'listings'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: this.translate.instant('Publications'),
                                icon: 'mi mi-checklist',
                                routerLink: [this.currentRoute, 'rentals', 'exposes', 'listing-publications'],
                            },
                        ]
                    },
                ]
            },
            {
                label: this.translate.instant('Move-Outs'),
                icon: 'mi mi-logout',
                items: [
                    {
                        label: this.translate.instant('Contract Terminations'),
                        icon: 'mi mi-highlight-off',
                        routerLink: [this.currentRoute, 'rentals', 'move-outs', 'contract-terminations'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: '1. ' + this.translate.instant('Termination Confirmations'),
                        items: [
                            {
                                label: 'All Confirmations',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'termination-confirmations'],
                            },
                            {
                              separator: true
                            },
                            {
                                label: '1. New',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'termination-confirmations'],
                                queryParams: {status: 'NEW'},
                            },
                            {
                                label: '2. Waiting',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'termination-confirmations'],
                                queryParams: {status: 'WAITING'},
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Archive',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'termination-confirmations'],
                                queryParams: {status: 'ARCHIVED'},
                            },
                        ]
                    },
                    {
                        label: '2. ' + this.translate.instant('Viewing Appointments'),
                        items: [
                            {
                                label: 'All Appointments',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'viewing-appointments'],
                            },
                            {
                              separator: true
                            },
                            {
                                label: '1. For Scheduling',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'viewing-appointments'],
                                queryParams: {status: 'FOR_SCHEDULING'},
                            },
                            {
                                label: '2. Scheduled',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'viewing-appointments'],
                                queryParams: {status: 'SCHEDULED'},
                            },
                            {separator: true},
                            {
                                label: 'Missed Appointments',
                                routerLink: [this.currentRoute, 'move-outs', 'missed-appointments'],
                            },
                            {
                                label: 'Rescheduled Appointments',
                                routerLink: [this.currentRoute, 'move-outs', 'rescheduled-appointments'],
                            }
                        ]
                    },
                    {
                        label: '3. ' + this.translate.instant('Handovers'),
                        items: [
                            {
                                label: 'All Handovers',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'handover-protocols'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: this.translate.instant('Handover Appointments'),
                                icon: 'pi pi-calendar',
                                items: [
                                    {
                                        label: 'All Appointments',
                                        icon: 'mi mi-list',
                                        routerLink: [this.currentRoute, 'rentals', 'move-outs', 'handover-appointments'],
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        label: '1. For Scheduling',
                                        routerLink: [this.currentRoute, 'rentals', 'move-outs', 'handover-appointments'],
                                        queryParams: {status: 'FOR_SCHEDULING'},
                                    },
                                    {
                                        label: '2. Scheduled',
                                        routerLink: [this.currentRoute, 'rentals', 'move-outs', 'handover-appointments'],
                                        queryParams: {status: 'SCHEDULED'},
                                    },
                                    {
                                        label: '3. For Examination',
                                        routerLink: [this.currentRoute, 'rentals', 'move-outs', 'handover-appointments'],
                                        queryParams: {status: 'FOR_EXAMINATION'},
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        label: 'Missed Appointments',
                                        routerLink: [this.currentRoute, 'move-outs', 'missed-appointments'],
                                        queryParams: {status: 'missed'},
                                    },
                                    {
                                        label: 'Rescheduled Appointments',
                                        routerLink: [this.currentRoute, 'move-outs', 'missed-appointments'],
                                        queryParams: {status: 'missed'},
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        label: '4. ' + this.translate.instant('Deposit Invoices'),
                        items: [
                            {
                                label: 'All Invoices',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'deposit-invoices'],
                            },
                            {
                              separator: true
                            },
                            {
                                label: '1. Drafts',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'deposit-invoices'],
                                queryParams: {status: 'DRAFT'},
                            },
                            {
                                label: '2. Waiting for Delivery',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'deposit-invoices'],
                                queryParams: {status: 'WAITING_FOR_DELIVERY'},
                            },
                            {
                                label: '3. Waiting for Payment',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'deposit-invoices'],
                                queryParams: {status: 'WAITING_FOR_PAYMENT'},
                            },
                            {
                                label: '4. Payment Lawsuits',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'payment-lawsuits?type=deposit'],
                                queryParams: {status: 'waiting-for-payment'},
                            },
                            {
                                separator: true
                            },

                            {
                                label: 'Done',
                                styleClass: 'archive-icon',
                                icon: 'pi pi-download',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'payment-lawsuits?type=deposit'],
                                queryParams: {status: 'waiting-for-payment'},
                            },
                        ]
                    },
                    {separator: true},
                    {
                        label: this.translate.instant('Archive'),
                        styleClass: 'archive-icon',
                        icon: 'pi pi-download',
                        routerLink: [this.currentRoute, 'rentals', 'move-outs', 'contract-terminations'],
                        queryParams: {status: 'archived'},
                    }
                ]
            },
            {
                label: this.translate.instant('Move-Ins'),
                icon: 'mi mi-exit-to-app',
                items: [
                    {
                        label: this.translate.instant('All Requests'),
                        icon: 'mi mi-assignment-ind',
                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'requests'],
                        routerLinkActiveOptions: {exact: true},
                    },
                    {
                        separator: true,
                    },
                    {
                        label: '1. ' + this.translate.instant('New Requests'),
                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'requests'],
                        queryParams: {status: RentRequestStatus.NEW_REQUEST},
                        routerLinkActiveOptions: {exact: true},
                    },
                    {
                        label: '2. ' + this.translate.instant('Simple Self-Disclosures'),
                        items: [
                            {
                                label: 'All Disclosures',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'simple-self-disclosures'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: '1. To Be Requested',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'simple-self-disclosures'],
                                queryParams: {status: 'TO_BE_REQUESTED'},
                            },
                            {
                                label: '2. Requested',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'simple-self-disclosures'],
                                queryParams: {status: 'REQUESTED'},
                            },
                            {
                                label: '3. For Examination',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'simple-self-disclosures'],
                                queryParams: {status: 'FOR_EXAMINATION'},
                            },
                        ]
                    },
                    {
                        label: '3. ' + this.translate.instant('Viewing Appointments'),
                        items: [
                            {
                                label: 'All Appointments',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'viewing-appointments'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: '1. For Scheduling',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'viewing-appointments'],
                                queryParams: {status: 'FOR_SCHEDULING'},
                            },
                            {
                                label: '2. Scheduled',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'viewing-appointments'],
                                queryParams: {status: 'SCHEDULED'},
                            },
                            {
                                label: '3. Past Appointments for Examination',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'viewing-appointments'],
                                queryParams: {status: 'PAST'},
                            },
                            {separator: true},
                            {
                                label: 'Missed Appointments',
                                routerLink: [this.currentRoute, 'move-outs', 'missed-appointments'],
                            },
                            {
                                label: 'Rescheduled Appointments',
                                routerLink: [this.currentRoute, 'move-outs', 'rescheduled-appointments'],
                            }
                        ]
                    },
                    {
                        label: '4. ' + this.translate.instant('Detailed Self-Disclosures'),
                        items: [
                            {
                                label: 'All Disclosures',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'detailed-self-disclosures'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: '1. To Be Requested',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'detailed-self-disclosures'],
                                queryParams: {status: 'TO_BE_REQUESTED'},
                            },
                            {
                                label: '2. Requested',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'detailed-self-disclosures'],
                                queryParams: {status: 'REQUESTED'},
                            },
                            {
                                label: '3. For Examination',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'detailed-self-disclosures'],
                                queryParams: {status: 'FOR_EXAMINATION'},
                            },
                        ]
                    },
                    {
                        label: '5. ' + this.translate.instant('Solvency Proofs'),
                        items: [
                            {
                                label: 'All Proofs',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'solvency-proofs'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: '1. To Be Requested',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'solvency-proofs'],
                                queryParams: {status: 'TO_BE_REQUESTED'},
                            },
                            {
                                label: '2. Requested',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'solvency-proofs'],
                                queryParams: {status: 'REQUESTED'},
                            },
                            {
                                label: '3. For Examination',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'solvency-proofs'],
                                queryParams: {status: 'FOR_EXAMINATION'},
                            },
                        ]
                    },
                    {
                        label: '6. ' + this.translate.instant('Contract Creations'),
                        items: [
                            {
                                label: 'All Contracts',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'contract-creations'],
                            },
                            {
                              separator: true
                            },
                            {
                                label: '1. Drafts',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'contract-creations'],
                                queryParams: {status: 'DRAFT'},
                            },
                            {
                                label: '2. Requested',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'contract-creations'],
                                queryParams: {status: 'REQUESTED'},
                            },
                            {
                                label: '3. Rejected',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'contract-creations'],
                                queryParams: {status: 'REJECTED'},
                            },
                        ]
                    },
                    {
                        label: '7. ' + this.translate.instant('Handovers'),
                        items: [
                            {
                                label: this.translate.instant('All Handovers'),
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'handover-protocols'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: this.translate.instant('Handover Appointments'),
                                icon: 'pi pi-calendar',
                                items: [
                                    {
                                        label: 'All Appointments',
                                        icon: 'mi mi-list',
                                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'handover-appointments'],
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        label: '1. For Scheduling',
                                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'handover-appointments'],
                                        queryParams: {status: 'FOR_SCHEDULING'},
                                    },
                                    {
                                        label: '2. Scheduled',
                                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'handover-appointments'],
                                        queryParams: {status: 'SCHEDULED'},
                                    },
                                    {
                                        label: '3. For Examination',
                                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'handover-appointments'],
                                        queryParams: {status: 'FOR_EXAMINATION'},
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        label: 'Missed Appointments',
                                        routerLink: [this.currentRoute, 'move-ins', 'missed-appointments'],
                                        queryParams: {status: 'missed'},
                                    },
                                    {
                                        label: 'Rescheduled Appointments',
                                        routerLink: [this.currentRoute, 'move-ins', 'missed-appointments'],
                                        queryParams: {status: 'missed'},
                                    }
                                ]

                            },
                            {
                                separator: true,
                            },
                            {
                                label: this.translate.instant('Welcome-Letters'),
                                icon: 'mi mi-local-post-office',
                                items: [
                                    {
                                        label: 'All Welcome-Letters',
                                        icon: 'mi mi-list',
                                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'welcome-letters', 'list'],
                                        queryParams: {status: 'FOR_SCHEDULING'},
                                    },
                                    {
                                        separator: true,
                                    },
                                    {
                                        label: 'Planned Information',
                                        icon: 'pi pi-info-circle',
                                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'welcome-letters', 'planned-information'],
                                        queryParams: {status: 'SCHEDULED'},
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        separator: true,
                    },
                    {
                        label: this.translate.instant('Waitlist'),
                        icon: 'mi mi-list',
                        queryParams: {isOnWaitingListCheck: true},
                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'requests'],
                        routerLinkActiveOptions: {exact: true},
                    },
                    {
                        separator: true,
                    },
                    {
                        label: this.translate.instant('Declines'),
                        icon: 'mi mi-disabled-by-default',
                        items: [
                            {
                                label: 'For Review',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'declines'],
                                queryParams: {status: 'FOR_REVIEW'},
                            },
                            {
                                separator: true,
                            },
                            {
                                label: 'All Declines',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'requests'],
                                queryParams: {status: RentRequestStatus.DECLINE},
                            },
                        ]
                    },
                ]
            },
        ]
    }
}
