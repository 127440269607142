
import {NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {MenubarModule} from 'primeng/menubar';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';

import {AuthComponent} from './system/auth/auth.component';
import {LoadingSpinnerComponent} from "./shared/loading-spinner/loading-spinner.component";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {AuthInterceptorService} from "./system/auth/auth-interceptor.service";
import {MenuModule} from "primeng/menu";
import {ToggleButtonModule} from "primeng/togglebutton";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TabViewModule} from "primeng/tabview";
import {HeaderComponent} from "./system/header/header.component";
import {ConfirmationService, MessageService} from "primeng/api";
import {DropdownModule} from "primeng/dropdown";
import {MasterModule} from "./system/master/master.module";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {DialogModule} from "primeng/dialog";
import {TableModule} from "primeng/table";
import { ErrorComponent } from './system/error/error.component';
import {ChooseEnterpriseComponent} from "./system/auth/choose-enterprise/choose-enterprise.component";
import {SpinnerModule} from "primeng/spinner";
import {FileUploadModule} from "primeng/fileupload";
import {environment} from "../environments/environment";
import {BadgeModule} from "primeng/badge";
import {CmdClickDirective} from "./system/directives/cmd-click.directive";

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {RippleModule} from "primeng/ripple";
import {ToastModule} from "primeng/toast";
import { ServiceWorkerModule } from '@angular/service-worker';
registerLocaleData(localeDe);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json' );
    // return new TranslateHttpLoader(http, environment.apiUrl + 'v1/translations/', '' );
}

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        LoadingSpinnerComponent,
        HeaderComponent,
        ErrorComponent,
        ChooseEnterpriseComponent,
        CmdClickDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MenubarModule,
        InputTextModule,
        ButtonModule,
        NgbModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de-DE',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ProgressSpinnerModule,
        MenuModule,
        ToggleButtonModule,
        BrowserAnimationsModule,
        ButtonModule,
        TabViewModule,
        DropdownModule,
        MasterModule,
        DialogModule,
        TableModule,
        SpinnerModule,
        FileUploadModule,
        BadgeModule,
        RippleModule,
        ToastModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        { provide: localeDe, useValue: 'de-DE' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        MessageService,
        ConfirmationService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

