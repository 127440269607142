import {TranslateService} from "@ngx-translate/core";

export class MenuMasterModel {


    menuItems:any;

    constructor(

        private translate: TranslateService,
        private currentRoute?: string,
    ) {

    this.menuItems = [
        {
            label: 'Settings',
            icon: 'mi mi-settings',
            routerLink: [this.currentRoute, 'master', 'settings'],
            routerLinkActiveOptions: {exact: true}
        },
        {
            label: 'Integrations',
            icon: 'pi pi-sitemap',
            styleClass: 'menuProfileSettings',
            routerLink: [this.currentRoute, 'master', 'settings', 'integrations'],
            routerLinkActiveOptions: {exact: true}
        },
        {
            label: 'Billing',
            icon: 'pi pi-credit-card',
            styleClass: 'menuMasterBilling',
            routerLink: [this.currentRoute, 'master', 'settings', 'billing'],
            routerLinkActiveOptions: {exact: true}
        },
        {
            label: 'Users',
            icon: 'pi pi-users',
            styleClass: 'menuMaster',
            routerLink: [this.currentRoute, 'master', 'users'],
            routerLinkActiveOptions: {exact: true}
        },
        {
            label: '...',
            icon: 'pi pi-users',
            styleClass: 'menuMaster',
            routerLinkActiveOptions: {exact: true},
            items: [
                {
                    label: 'ToS',
                    icon: 'pi pi-users',
                    styleClass: 'menuMaster',
                    routerLink: [this.currentRoute, 'master', 'users'],
                    routerLinkActiveOptions: {exact: true}
                },
                {
                    label: 'Privacy Policy',
                    icon: 'pi pi-users',
                    styleClass: 'menuMaster',
                    routerLink: [this.currentRoute, 'master', 'users'],
                    routerLinkActiveOptions: {exact: true}
                },
            ]
        },
    ]
}


}




