
export class UserEnterprise {
    name?: string;
    id?: string;
    fullAddress?: string;
    language?: string;
}

export class User {


    constructor(
        public email: string,
        public firstName: string,
        public lastName: string,
        private _access_token: string,
        private _tokenExpirationDate: Date,
        public darkMode:boolean,
        public language:string,
        public enterprises: UserEnterprise[],
    ) {}

    //getter, runs when you access the token method. You can not set from outside.
    get token() {

        if(!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
            return null;
        }
        return this._access_token;
    }

}
