import {MenuBasicModel} from "../menu-models/menu-basic.model";
import {MenuOfficeModel} from "../menu-models/menu-office.model";
import {MenuManagementModel} from "../menu-models/menu-management.model";
import {MenuRentalsModel} from "../menu-models/menu-rentals.model";
import {MenuProfileModel} from "../menu-models/menu-profile.model";
import {EventEmitter, Injectable} from "@angular/core";
import {AuthService} from "../../auth/auth.service";
import {MenuSalesModel} from "../menu-models/menu-sales.model";
import {MenuPropertiesModel} from "../menu-models/menu-properties.model";
import {MenuMasterModel} from "../menu-models/menu-master.model";
import {MenuUserModel} from "../menu-models/menu-user.model";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ThemeService} from "../../../shared/theme.service";
import {MenuItem} from "primeng/api";
import {HttpClient} from "@angular/common/http";
import {HeaderBadgeService} from "./header-badge.service";


interface City {
    name: string,
    code: string
}

@Injectable({providedIn: 'root'})
export class HeaderService {

    currentRoute: string;

    menuBasic = new MenuBasicModel(this.translate);
    menuOffice = new MenuOfficeModel(this.translate);
    menuProperties = new MenuPropertiesModel(this.translate);
    menuManagement = new MenuManagementModel(this.translate);
    menuRental = new MenuRentalsModel(this.translate);
    menuSales = new MenuSalesModel(this.translate);
    menuProfile = new MenuProfileModel(this.translate);
    menuUser = new MenuUserModel(this.translate);
    menuMaster = new MenuMasterModel(this.translate);
    headerMenuItems: any = [];
    headerMenuItemsUpdate = new EventEmitter<MenuItem[]>()
    badgeCountChangeEventEmitter: EventEmitter<any> = new EventEmitter<any>();


    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private translate: TranslateService,
        private themeService: ThemeService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.headerMenuItems = [this.menuBasic.menuItems] as MenuItem[];


        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = ('/' + event.url.split('/')[1] + '/' + event.url.split('/')[2]);
                this.changeMenuItems(event);
            }
        });

        this.translate.onLangChange.subscribe(() => {
            this.menuProfile.translateMenuProfile();
            this.menuBasic.translateMenuItems();
            const navRoute = this.router.url.split('/')[3];
            if (navRoute === 'user') {
                this.headerMenuItems[0].label = this.translate.instant('User');
            } else if (navRoute === 'organization') {
                this.headerMenuItems[0].label = this.translate.instant('Organization');
            }
            this.headerMenuItemsUpdate.emit(this.headerMenuItems as MenuItem[]);
        })

    }

    changeMenuItems(event: any) {
        this.menuProfile = new MenuProfileModel(this.translate, this.currentRoute);
        this.menuProfile.onThemeModeToggle.subscribe(() => this.switchTheme());
        this.menuProfile.logout.subscribe(() => this.onLogout());
        this.menuBasic = new MenuBasicModel(this.translate, this.currentRoute);
        this.headerMenuItems.unshift(...this.menuBasic.menuItems[0]);
        const lastRoute = event.url
            .split('/')
            .slice(3) // Skip the first three elements
            .map((segment) => segment.split('?')[0]);
        if (lastRoute.length) {
            localStorage.setItem('lastRoute', lastRoute.join(','));
        }
        this.changeNavigation(event.url.split('/')[3]);
    }

    changeNavigation(navRoute: string) {

        switch (navRoute) {
            case 'office':
                this.onChangeRoute(new MenuOfficeModel(this.translate, this.currentRoute), 'Office', 'mi mi-computer',);
                break;
            case 'properties':
                this.onChangeRoute(new MenuPropertiesModel(this.translate, this.currentRoute), 'Properties', 'mi mi-apartment');
                break;
            case 'management':
                this.onChangeRoute(new MenuManagementModel(this.translate, this.currentRoute), 'Management', 'mi mi-room-preferences');
                break;
            case 'rentals':
                this.onChangeRoute(new MenuRentalsModel(this.translate, this.currentRoute), 'Rentals', 'mi mi-family-restroom');
                break;
            case 'sales':
                this.onChangeRoute(new MenuSalesModel(this.translate, this.currentRoute), 'Sales', 'mi mi-real-estate-agent');
                break;
            case 'master':
                this.onChangeRoute(new MenuMasterModel(this.translate, this.currentRoute), 'Organization', 'mi mi-settings-applications');
                break;
            case 'user':
                this.onChangeRoute(new MenuUserModel(this.translate, this.currentRoute), 'User', 'mi mi-person');
                break;
        }
    }

    private onChangeRoute(menuModel: any, name: string, iconClass: string) {
        this.menuBasic.changeActiveItem(name, iconClass);
        this.headerMenuItems.splice(1);
        this.headerMenuItems.push(...menuModel.menuItems);
        this.headerMenuItemsUpdate.emit(this.headerMenuItems as MenuItem[]);
    }

    navigate(route: string[]) {
        this.router.navigate([[this.currentRoute] + '/' + route.join('/')]);
    }


    switchTheme() {
        this.themeService.switchTheme();
    }

    onLogout() {
        this.authService.logout();
    }


}
