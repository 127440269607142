import {
    AfterContentChecked, AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter, HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {debounceTime, Subscription, take, timeout} from "rxjs";
import {MenuItem, PrimeIcons, SelectItem} from 'primeng/api';
import {AuthService} from "../auth/auth.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {HeaderService} from "./services/header.service";
import {TranslateService} from "@ngx-translate/core";
import {UserEnterprise} from "../auth/user.model";
import {ChooseEnterpriseService} from "../auth/choose-enterprise/choose-enterprise.service";
import {Menubar} from "primeng/menubar";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {HeaderBadgeCountData, HeaderBadgeService} from "./services/header-badge.service";

interface Country {
    name: string,
    code: string,
    img: string
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit, OnInit, AfterViewChecked, OnDestroy {


    private userSub: Subscription = new Subscription;
    isAuthenticated: boolean = false;
    PrimeIcons = PrimeIcons;
    headerMenuItems: any = [];
    profileItems: MenuItem[];
    menuItemSub: Subscription;
    visible: boolean = false;
    products: any;
    enterprises: UserEnterprise[];
    fullCurrentRoute: any;

    activeEnterpriseId: string;
    activeEnterpriseName: string;


    @ViewChild('menubar') menubar: Menubar;
    hostListenerSuccess: boolean = true;
    mobileMenuItems: MenuItem[] = [];
    isMobileMenuActive: boolean;
    lastMenuItems: MenuItem[] = [];
    collapsedMenubarItems: { item: [], width: number }[] = [];
    collapsedMenuItem: MenuItem = {
        label: '',
        icon: 'pi pi-ellipsis-h',
        items: []
    };
    @ViewChild('menubar1') menubar1: ElementRef;
    @ViewChild('menubarEnd') menubarEnd: ElementRef;
    resizeEvent = new EventEmitter();
    resizeEventSub: Subscription;

    screenWidth!: number;

    headerBadgeCountData: HeaderBadgeCountData[];

    appointmentBadgeCount: string = '';

    private routerSub: Subscription;


    constructor(
        private authService: AuthService,
        private headerService: HeaderService,
        private headerBadgeService: HeaderBadgeService,
        private router: Router,
        private renderer: Renderer2,
        private translateService: TranslateService,
        private chooseEnterpriseService: ChooseEnterpriseService,
        private cdr: ChangeDetectorRef,
        private http: HttpClient,
    ) {
    }


    ngOnInit() {

        this.userSub = this.authService.user.subscribe(user => {
            this.isAuthenticated = !!user;
            if (user?.enterprises) {
                this.enterprises = user.enterprises;
            }
        });


        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const activeEnterpriseId = event.url.split('/')[1];
                console.log('___ACTIVE_ENTERPRISE_ID___HD_TOOLBAR', activeEnterpriseId);

                const activeEnterpriseName = this.authService.user.value.enterprises.find(enterprise => enterprise.id === activeEnterpriseId).name;

                const activeEnterpriseNameParts = activeEnterpriseName.split(' ')

                this.activeEnterpriseName = '';
                if (activeEnterpriseNameParts.length > 1) {
                    for (const part of activeEnterpriseNameParts) {
                        this.activeEnterpriseName += part.charAt(0).toUpperCase();
                    }
                }

            }
        });

        this.headerService.headerMenuItemsUpdate.subscribe(headerMenuItems => {
            this.profileItems = this.headerService.menuProfile.menuItems;
            this.headerMenuItems = [...headerMenuItems];
            this.lastMenuItems = [...headerMenuItems];
            this.mobileMenuItems = [...headerMenuItems];
        });

        this.resizeEventSub = this.resizeEvent.pipe(debounceTime(50)).subscribe(() => {
            this.onResize(null);
        });


    }


    ngAfterViewInit() {
    }

    ngAfterViewChecked() {
        // this.resizeEvent.emit();
    }


    navigate(route: string[]) {
        this.headerService.navigate(route)
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
        this.userSub.unsubscribe();
        this.resizeEventSub.unsubscribe();
    }


    // Listen for window resize events
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.screenWidth = window.innerWidth;
        const menubarWidth = this.menubar1.nativeElement.offsetWidth;
        const menubarEndWidth = this.menubarEnd.nativeElement.offsetWidth;
        const menubarStartWidth = this.screenWidth - menubarEndWidth;

        // Only select the top-level menu items
        const topMenuItems = this.menubar.el.nativeElement.querySelectorAll('.p-menubar-root-list > li.p-menuitem');
        let totalMenuItemsWith = 0;
        topMenuItems.forEach((menuItem: HTMLElement) => {
            totalMenuItemsWith += menuItem.offsetWidth + 6;
        });

        console.log('MENU_BAR_START_WIDTH:' + menubarStartWidth)
        console.log(this.isMobileMenuActive);

        if (this.isMobileMenuActive && (menubarStartWidth >= 747)) {
            this.headerMenuItems = this.lastMenuItems;
            this.isMobileMenuActive = false;
        }

        if (((totalMenuItemsWith + 16) > menubarStartWidth) && (menubarStartWidth >= 747)) {
            console.log('COLLAPSE')
            const collapse = () => {
                // remove one menuItem
                let itemToCollapse
                if (this.headerMenuItems[this.headerMenuItems?.length - 1].icon === 'pi pi-ellipsis-h') {
                    itemToCollapse = this.headerMenuItems[this.headerMenuItems?.length - 2];
                    this.headerMenuItems = this.headerMenuItems.slice(0, this.headerMenuItems.length - 2);
                    this.collapsedMenubarItems.push({
                        item: itemToCollapse,
                        width: topMenuItems[topMenuItems.length - 2].offsetWidth
                    });
                } else {
                    itemToCollapse = this.headerMenuItems.pop();
                    this.collapsedMenubarItems.push({
                        item: itemToCollapse,
                        width: topMenuItems[topMenuItems.length - 1].offsetWidth
                    });
                }
                this.collapsedMenuItem.items.unshift(itemToCollapse);
                totalMenuItemsWith -= itemToCollapse.width;
                this.headerMenuItems = [...this.headerMenuItems, this.collapsedMenuItem];
            }
            while ((totalMenuItemsWith + 16) > menubarStartWidth) {
                collapse();
            }
            this.menubar?.cd.detectChanges();
        } else if (menubarStartWidth >= 747) {
            console.log('EXPAND')
            console.log(this.collapsedMenubarItems)
            const firstCollapsedItemWidth = (this.collapsedMenubarItems.length > 0 ? this.collapsedMenubarItems[this.collapsedMenubarItems?.length - 1].width : 0);
            totalMenuItemsWith += firstCollapsedItemWidth + 21;
            if ((this.collapsedMenubarItems.length > 0) && (totalMenuItemsWith < menubarStartWidth) && (menubarStartWidth >= 750)) {
                console.log('___COLLAPSED_MENU_ITEMS____')
                console.log(this.collapsedMenubarItems)
                const itemToExpand = this.collapsedMenubarItems.pop().item;
                this.headerMenuItems.splice(this.headerMenuItems.length - 1, 0, itemToExpand);
                this.collapsedMenuItem.items = this.collapsedMenuItem.items.slice(1, this.collapsedMenuItem.items.length);

                if (this.headerMenuItems[this.headerMenuItems?.length - 1].icon === 'pi pi-ellipsis-h' && (this.collapsedMenuItem.items.length === 0)) {
                    this.headerMenuItems = this.headerMenuItems.slice(0, this.headerMenuItems.length - 1);
                }

                this.menubar?.cd.detectChanges();
            }
        } else {
            if (!this.isMobileMenuActive) {
                this.lastMenuItems = Object.assign([], this.headerMenuItems);
                this.headerMenuItems = this.mobileMenuItems;
                this.isMobileMenuActive = true;
            }
        }
    }

    onChooseEnterprise() {
        this.router.navigate(['choose-enterprise']);
    }


}
