import {EventEmitter} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

export class MenuProfileModel {

    public logout = new EventEmitter();

    public onThemeModeToggle = new EventEmitter();

    menuItems: any = [];


    constructor(
        private translate: TranslateService,
        private currentRoute?: string,
        private darkMode?: boolean,
    ) {

        this.menuItems = [
            {
                label: 'User',
                items: [
                    {label: this.translate.instant('Settings'), routerLink: [this.currentRoute, 'user', 'settings'], icon: 'pi pi-user-edit'},
                    {label: 'Help & FAQ', routerLink: [this.currentRoute, 'user', 'help'], icon: 'pi pi-question-circle'},
                    {label: 'Dark mode', icon: darkMode ? 'pi pi-sun' : 'pi pi-moon', command: () => this.switchTheme()}
                ]
            },
            {
                label: ''
            },
            {
                label: 'Organization',
                items: [
                    {label: 'Settings', routerLink: [this.currentRoute, 'master', 'settings'], icon: 'mi mi-settings-applications'},
                    {label: 'Integrations', routerLink: [this.currentRoute, 'master', 'settings', 'integrations'], icon: 'pi pi-sitemap'},
                    {label: 'Billing', routerLink: [this.currentRoute, 'master', 'settings', 'billings'], icon: 'pi pi-credit-card'},
                    {label: 'Users', routerLink: [this.currentRoute, 'master', 'users'], icon: 'pi pi-users'},
                    {label: 'ToS', routerLink: [this.currentRoute, 'legal', 'termsofservice'], icon: 'pi pi-file'},
                    {label: 'Privacy Policy', routerLink: [this.currentRoute, 'legal', 'privacypolicy'], icon: 'pi pi-lock'}
                ]
            },
            {
                label: '',
                items: [
                    {
                        label: 'Log out',
                        icon: 'pi pi-sign-out',
                        command: () => this.onLogout()
                    },
                ]
            },
        ]

        this.setDarkMode(Boolean(localStorage.getItem('darkMode')));

    }

    translateMenuProfile() {
        this.menuItems[0].label = this.translate.instant('User');
        this.menuItems[0].items[0].label = this.translate.instant('Settings');
        this.menuItems[0].items[1].label = this.translate.instant('Help & FAQ');
        this.menuItems[0].items[2].label = this.translate.instant('Dark mode');
        this.menuItems[2].label = this.translate.instant('Organization');
        this.menuItems[2].items[0].label = this.translate.instant('Settings');
        this.menuItems[2].items[1].label = this.translate.instant('Integrations');
        this.menuItems[2].items[2].label = this.translate.instant('Billing');
        this.menuItems[2].items[3].label = this.translate.instant('Users');
        this.menuItems[2].items[4].label = this.translate.instant('GTCs');
        this.menuItems[2].items[5].label = this.translate.instant('Privacy');
        this.menuItems[3].items[0].label = this.translate.instant('Log out');
    }


    setDarkMode(darkMode?:boolean) {
        if(darkMode) {
            this.menuItems[0].items[2].label = this.translate.instant('Light mode');
            this.menuItems[0].items[2].icon = 'pi pi-sun';
        } else {
            this.menuItems[0].items[2].label = this.translate.instant('Dark mode');
            this.menuItems[0].items[2].icon = 'pi pi-moon';
        }
    }

    onLogout() {
        this.logout.emit();
    }

    switchTheme() {
        const icon = this.menuItems[0].items[2].icon;
        if(icon === 'pi pi-sun') {
            this.menuItems[0].items[2].label = this.translate.instant('Dark mode');
            this.menuItems[0].items[2].icon = 'pi pi-moon';
            this.onThemeModeToggle.emit(true)
        } else {
            this.menuItems[0].items[2].label = this.translate.instant('Light mode');
            this.menuItems[0].items[2].icon = 'pi pi-sun';
            this.onThemeModeToggle.emit(false)
        }
    }

}
