import {TranslateService} from "@ngx-translate/core";
import {MenuItem} from "primeng/api";

export class MenuOfficeModel {

    menuItems: MenuItem[] = [];

    constructor(
        private translate: TranslateService,
        private currentRoute?: string,
    ) {


        this.menuItems = [
            {
                label: this.translate.instant('Contacts'),
                icon: 'pi pi-list',
                styleClass: 'menuOfficeContactIcon',
                routerLink: [this.currentRoute, 'office', 'contacts'],
                routerLinkActiveOptions: {exact: false},
            },
            {
                label: this.translate.instant('Mailbox'),
                icon: 'pi pi-list',
                styleClass: 'menuOfficeMailboxIcon',
                routerLink: [this.currentRoute, 'office', 'mailbox'],
                routerLinkActiveOptions: {exact: false},
            },
            {
                label: this.translate.instant('Writings'),
                icon: 'pi pi-list',
                styleClass: 'writings-icon',
                routerLinkActiveOptions: {exact: false},
                items: [
                    {
                        label: this.translate.instant('All Writings'),
                        routerLink: [this.currentRoute, 'office', 'writings'],
                        icon: 'pi pi-list',
                        styleClass: 'writings-icon',
                    },
                    {
                        separator: true
                    },
                    {
                        label: this.translate.instant('Delivery Attempts'),
                        routerLink: [this.currentRoute, 'office', 'writing-deliveries'],
                        icon: 'pi pi-list',
                        styleClass: 'writing-deliveries-icon',
                    }
                ]
            },
            {
                label: 'Accounting',
                icon: 'mi mi-menu-book',
                routerLinkActiveOptions: {exact: false},
                items: [
                    {
                        label: 'Documents',
                        icon: 'mi mi-library-books',
                        styleClass: 'documentsIcon',
                        items: [
                            {
                                label: 'Invoices',
                                icon: 'mi mi-description',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'documents', 'invoices'],
                            },
                            {
                                label: 'Contracts',
                                icon: 'mi mi-file-present',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'documents', 'other'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Financial Statements',
                                icon: 'mi mi-receipt-long',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'documents', 'financial-statements'],
                            },
                            {
                                label: 'Tax Documents',
                                icon: 'mi mi-receipt-long',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'documents', 'tax-documents'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Supplementary Documents',
                                icon: 'mi mi-file-present',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'documents', 'supplementary'],
                            },
                        ]
                    },
                    {
                        label: 'Payrolls',
                        routerLink: [this.currentRoute, 'office', 'accounting', 'payrolls'],
                        icon: 'mi mi-dynamic-feed',
                        items: [
                            {
                                label: 'All Payrolls',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'payrolls'],
                                icon: 'mi mi-library-books',
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Employee-Payrolls',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'employee-payrolls'],
                                icon: 'mi mi-receipt-long',
                            },
                            {
                                label: 'Payroll-Documents',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'payroll-documents'],
                                icon: 'mi mi-file-present',
                            },

                        ]
                    },
                    {
                        label: 'Banking',
                        icon: 'mi mi-account-balance',
                        styleClass: 'mi bankAccountsIcon3',
                        items: [
                            {
                                label: 'Bank-Accounts',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'bank-accounts'],
                                icon: 'mi mi-account-balance',
                            },
                            {
                                label: 'Bank-Transactions',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'transactions'],
                                icon: 'mi mi-list',
                            },
                            {
                                label: 'Payments',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'payments'],
                                icon: 'mi mi-payments',
                            },
                        ]
                    },
                    {
                        label: 'Payment-Claims',
                        icon: 'mi mi-assignment-late',
                        items: [
                            {
                                label: 'Payment-Claims',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'payment-claims'],
                                icon: 'mi mi-assignment-late',
                            },
                            {
                                separator: true,
                            },
                            {
                                label: 'Dunnings',
                                routerLink: [this.currentRoute, 'office', 'accounting', 'payment-dunnings'],
                                icon: 'mi mi-warning',
                            },
                        ]
                    },
                ]
            },
            {
                label: 'Vendors',
                icon: 'mi mi-handshake',
                routerLinkActiveOptions: {exact: false},
                items: [
                    {
                        label: 'Vendors',
                        icon: 'mi mi-storefront',
                        routerLink: [this.currentRoute, 'office', 'vendors'],
                        routerLinkActiveOptions: {exact: false}
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Contacts',
                        icon: 'mi mi-person',
                        routerLink: [this.currentRoute, 'office', 'vendor-contacts'],
                        routerLinkActiveOptions: {exact: false},
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Contracts',
                        icon: 'mi mi-description',
                        routerLinkActiveOptions: {exact: false},
                        items: [
                            {
                                label: 'All Contracts',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'office', 'vendor-contracts'],
                                routerLinkActiveOptions: {exact: false}
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Contract-Supplements',
                                icon: 'mi mi-post-add',
                                routerLink: [this.currentRoute, 'office', 'vendor-contract-supplements'],
                                routerLinkActiveOptions: {exact: false}
                            },
                            {
                                label: 'Contract-Terminations',
                                icon: 'mi mi-highlight-off',
                                routerLink: [this.currentRoute, 'office', 'vendor-contract-terminations'],
                                routerLinkActiveOptions: {exact: false}
                            },
                        ]
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Products',
                        icon: 'mi mi-storefront',
                        routerLinkActiveOptions: {exact: false},
                        items: [
                            {
                                label: 'Manufacturers',
                                icon: 'mi mi-factory',
                                routerLink: [this.currentRoute, 'office', 'product-manufacturers'],
                                routerLinkActiveOptions: {exact: false},
                            },
                            {
                                label: 'Goods',
                                icon: 'mi mi-local-offer',
                                routerLink: [this.currentRoute, 'office', 'products'],
                                routerLinkActiveOptions: {exact: false},
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Goods',
                                icon: 'mi mi-local-offer',
                                routerLink: [this.currentRoute, 'office', 'products'],
                                routerLinkActiveOptions: {exact: false},
                            },
                            {
                                label: 'Services',
                                icon: 'mi mi-room-service',
                                routerLink: [this.currentRoute, 'office', 'vendor-services'],
                                routerLinkActiveOptions: {exact: false}
                            },
                        ]
                    },
                ]
            },
            {
                label: 'Employees',
                icon: 'mi mi-badge',
                items: [
                    {
                        label: 'Employees',
                        icon: 'mi mi-badge',
                        routerLink: [this.currentRoute, 'office', 'hr', 'employees',],
                        routerLinkActiveOptions: {exact: false},
                    },
                    {separator: true},
                    {
                        label: 'Contracts',
                        icon: 'mi mi-description',
                        routerLinkActiveOptions: {exact: false},
                        items: [
                            {
                                label: 'All Contracts',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'office', 'hr', 'employment-contracts'],
                                routerLinkActiveOptions: {exact: false},
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Contract-Supplements',
                                icon: 'mi mi-post-add',
                                routerLink: [this.currentRoute, 'office', 'hr', 'employment-contract-supplements'],
                                routerLinkActiveOptions: {exact: false},
                            },
                            {
                                label: 'Contract-Terminations',
                                icon: 'mi mi-highlight-off',
                                routerLink: [this.currentRoute, 'office', 'hr', 'employment-contract-terminations'],
                                routerLinkActiveOptions: {exact: false},
                            },
                        ]
                    },
                    {
                      separator: true
                    },
                    {
                        label: 'Trainings',
                        icon: 'mi mi-school',
                        routerLink: [this.currentRoute, 'office', 'hr', 'employee-trainings'],
                        routerLinkActiveOptions: {exact: false},
                        items: [
                            {
                                label: '1. Planned',
                                routerLink: [this.currentRoute, 'office', 'hr', 'employees'],
                                routerLinkActiveOptions: {exact: false},
                            },
                            {
                                label: '2. In Process',
                                routerLink: [this.currentRoute, 'office', 'hr', 'employees'],
                                routerLinkActiveOptions: {exact: false},
                            },
                            {
                                label: '3. Overdue',
                                routerLink: [this.currentRoute, 'office', 'hr', 'employees'],
                                routerLinkActiveOptions: {exact: false},
                            },
                            {
                                label: '4. Done',
                                routerLink: [this.currentRoute, 'office', 'hr', 'employees'],
                                routerLinkActiveOptions: {exact: false},
                            },
                        ]
                    },
                    {
                        label: 'Reviews',
                        icon: 'mi mi-rate-review',
                        routerLink: [this.currentRoute, 'office', 'hr', 'employee-reviews'],
                        routerLinkActiveOptions: {exact: false},
                    },
                    {
                        label: 'Warnings',
                        icon: 'mi mi-error-outline',
                        routerLink: [this.currentRoute, 'office', 'hr', 'employee-warnings'],
                        routerLinkActiveOptions: {exact: false},
                    },
                ],
            },
            {
                label: 'Jobs',
                icon: 'mi mi-work-outline',
                styleClass: 'documentsIcon',
                items: [
                    {
                        label: 'Descriptions',
                        icon: 'mi mi-article',
                        routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'job-descriptions'],
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Listings',
                        icon: 'mi mi-list-alt',
                        routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'job-listings'],
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Applications',
                        icon: 'mi mi-assignment-ind',
                        items: [
                            {
                                label: '1. Applications',
                                routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'job-applications'],
                            },
                            {
                                label: '2. Pre-Qualification-Tests',
                                routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'applications-pre-qualification-tests'],
                            },
                            {
                                label: '3. Final-Qualification-Tests',
                                routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'applications-final-qualification-tests'],
                            },
                            {
                                label: '4. Qualification-Interviews',
                                routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'applications-qualification-interviews'],
                            },
                            {
                                label: '5. Possible Candidates',
                                routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'applications-possible-candidates'],
                            },
                        ]
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Contract-Creations',
                        icon: 'mi mi-assignment-turned-in',
                        items: [
                            {
                                label: 'Personal Questionnaires',
                                icon: 'mi mi-assignment-turned-in',
                                routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'personal-questionnaires'],
                            },
                            {
                                separator: true,
                            },
                            {
                                label: 'Contract-Creations',
                                icon: 'mi mi-note-add',
                                routerLink: [this.currentRoute, 'office', 'hr', 'jobs', 'contract-creations'],
                            },
                        ]
                    },
                ]
            },
            {
                label: 'Lawsuits',
                icon: 'mi mi-gavel',
                routerLink: [this.currentRoute, 'office', 'law-suits'],
                routerLinkActiveOptions: {exact: false},
            },
            {
                label: 'Tasks',
                icon: 'mi mi-task-alt',
                routerLink: [this.currentRoute, 'office', 'tasks'],
                routerLinkActiveOptions: {exact: false},
            },
        ]

        this.translateMenuItems();

    }


    translateMenuItems() {

        for (let item of this.menuItems) {
            if (item.label) {
                item.label = this.translate.instant(item.label);
            }
            if (item.items) {
                for (let subItem of item.items) {
                    if (subItem.label) {
                        subItem.label = this.translate.instant(subItem.label);
                    }
                    if (subItem.items) {
                        for (let subItem2 of subItem.items) {
                            if (subItem2.label) {
                                subItem2.label = this.translate.instant(subItem2.label);
                            }
                        }
                    }
                }
            }
        }
    }

}




