import {Injectable} from "@angular/core";


@Injectable({providedIn: "root"})
export class ChooseEnterpriseService {

    visibility: { state: boolean } = {state: false};

    userEnterprise: any = {id: ''};

}
