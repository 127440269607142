import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from "@angular/router";
import {AuthService} from "../auth.service";
import {Subscription} from "rxjs";
import {ChooseEnterpriseService} from "./choose-enterprise.service";
import {readableStreamLikeToAsyncGenerator} from "rxjs/internal/util/isReadableStreamLike";

@Component({
    selector: 'app-choose-enterprise',
    templateUrl: './choose-enterprise.component.html',
    styleUrls: ['./choose-enterprise.component.scss',]
})
export class ChooseEnterpriseComponent implements OnDestroy {

    visibility: any = {state: false};
    enterprises: any[];
    activeUserEnterprise: boolean = false;
    isAuthenticated: boolean = false;
    userEnterprise: any = {id: ''}
    filterTerm: string;
    filteredList: any[];

    private userSub: Subscription = new Subscription;
    private routerSub: Subscription = new Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private chooseEnterpriseService: ChooseEnterpriseService,
    ) {

        this.userSub = this.authService.user.subscribe(user => {
            this.isAuthenticated = !!user;
            if (user?.enterprises) {
                this.enterprises = user.enterprises;
                this.filteredList = this.enterprises;
            }
        });

        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url.split('/')[1] === 'choose-enterprise') {
                    this.chooseEnterpriseService.visibility.state = true;
                    this.activeUserEnterprise = false;
                } else {
                    this.userEnterprise.id = this.chooseEnterpriseService?.userEnterprise?.id;
                    this.activeUserEnterprise = true;
                }
            }
        });

        this.visibility = this.chooseEnterpriseService.visibility;

    }


    ngOnDestroy() {
        this.userSub.unsubscribe();
        this.routerSub.unsubscribe();
    }

    changeEnterprise(enterpriseId: string) {

        console.log('changeEnterprise')
        console.log(enterpriseId)

        console.log(this.router.url.split('/'))

        const route = this.router.url.split('/');
        // Assuming 'route' is an array containing the route segments
        const currentRoute = route.slice(3).map(segment => segment.split('?')[0]);
        // slice first 3 elements of route array and query params
        const currentRouteQueryParams = this.route.snapshot.queryParams;
        const languageCode = route[2];
        const defaultRoute = '/de/office'
        console.log('CURRENT_ROUTE')
        console.log(currentRoute)

        localStorage.setItem('activeEnterprise', enterpriseId);

        if(!(currentRoute?.length > 0)) {
            console.log('CHANGE ROUTE TO ' + enterpriseId + defaultRoute)

            const lastRoute = localStorage.getItem('lastRoute')?.split(',');

            if (lastRoute?.length > 1) {
                this.router.navigate([enterpriseId + '/' + 'de' + '/' + lastRoute.join('/')], {queryParams: currentRouteQueryParams}).then(success => {
                    if (success) {
                        console.log('Navigation succeeded');
                        console.log()
                    } else {
                        this.router.navigate([enterpriseId + '/de/office'])
                    }
                }).catch(err => {
                    console.log('Navigation failed');
                    console.log(err)
                    this.router.navigate([enterpriseId + defaultRoute])
                });
            } else {
                this.router.navigate([enterpriseId + defaultRoute])
            }

        } else {
            console.log('CHANGE ROUTE TO ' + enterpriseId + '/' + 'de' + '/' + currentRoute.join('/'))
            try {
                this.router.navigate([enterpriseId + '/' + 'de' + '/' + currentRoute.join('/')], {queryParams: currentRouteQueryParams}).then(success => {
                    if (success) {
                        console.log('Navigation succeeded');
                        localStorage.setItem('lastRoute', currentRoute.join('/'));
                    } else {
                        this.router.navigate([enterpriseId + defaultRoute])
                    }
                })
                console.log('Navigation succeeded');
                localStorage.setItem('lastRoute', currentRoute.join('/'));
            } catch (e) {
                console.log(e)
                console.log('Navigation failed');
                this.router.navigate([enterpriseId + defaultRoute])
            }
        }

            this.chooseEnterpriseService.visibility.state = false;

            setTimeout(() => {
                this.filteredList = this.enterprises;
                this.filterTerm = '';
            }, 1000)


    }

    checkActiveUserEnterprise(enterpriseId: any) {
        if (enterpriseId === this.router.url.split('/')[1]) {
            return 'active';
        }
        return ''
    }

    filterList() {
        if (this.filterTerm) {
            this.filteredList = this.enterprises.filter(item => {
                return item.name.toLowerCase().includes(this.filterTerm.toLowerCase())
            });
        } else {
            this.filteredList = this.enterprises;
        }
    }
}
