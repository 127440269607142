import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";


@Injectable({providedIn: 'root'})
export class EnterpriseCountryGuard {

    constructor(
        private router: Router,
        private translate: TranslateService,
    ) {


    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        const language = route.url[0].path
        if (language === 'de' ) {
            this.translate.use('de-DE');
            return true;
        } else if (language === 'en') {
            this.translate.use('en-US');
            return true;
        } else {
            this.router.navigate(['/de/1/error']);
            return false;
        }
    }

}
