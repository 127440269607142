import {Component, Injectable, OnDestroy} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";

import {AuthService, AuthResponseData} from "./auth.service";
import {Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})


export class AuthComponent implements OnDestroy {
    private authForm = NgForm;
    private userSub: Subscription = new Subscription;
    private authSub: Subscription = new Subscription;
    isAuthenticated: boolean = false;
    isLoginMode: boolean = true;
    isLoading: boolean = false;
    error: string = '';

    constructor(
        private titleService:Title,
        private authService: AuthService,
        private activatedRoute:ActivatedRoute,
        private router:Router
    ) {

        this.titleService.setTitle('Login');

        this.userSub = this.authService.user.subscribe(user => {
            this.isAuthenticated = !!user;
            if(this.isAuthenticated && user && user.enterprises && user?.enterprises.length > 0) {

                this.titleService.setTitle(environment.appTitle);

/*                const redirectUrl = localStorage.getItem('redirectUrl') || '/choose-enterprise';
                this.router.navigateByUrl(redirectUrl);*/
            }
        })

    }

    onSwitchMode() {
        this.isLoginMode = !this.isLoginMode;
    }

    onSubmit(form: NgForm) {
        if(!form.valid) {
            return;
        }

        this.isLoading = true;

        const email = form.value.email;
        const password = form.value.password;

        let authObs: Observable<AuthResponseData>;

        authObs = this.authService.login(email, password);

        this.authSub = authObs.subscribe(
            resData => {
                this.isLoading = false;
                if(resData?.user?.enterprises) {
                    const userEnterprises:any = resData.user.enterprises;
                    if(userEnterprises && userEnterprises.length > 0) {
                        this.router.navigate(['choose-enterprise'])
                    } else if (userEnterprises) {
                        if(userEnterprises?.id) {
                            this.router.navigate([userEnterprises.id, 'de', 'office'])
                        }
                    }
                }
            },
            errorMessage => {
                console.log(errorMessage);
                this.error = errorMessage;
                this.isLoading = false;
            }
        );

        form.reset();
    }

    ngOnDestroy() {
        this.userSub.unsubscribe();
        this.authSub.unsubscribe();
    }


}
