import {Component, OnInit} from '@angular/core';
import { Title } from "@angular/platform-browser";
import {AuthService} from "./system/auth/auth.service";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd, NavigationError,
  NavigationSkipped,
  NavigationStart,
  Router
} from "@angular/router";
import {HeaderService} from "./system/header/services/header.service";
import {ThemeService} from "./shared/theme.service";
import {environment} from "../environments/environment";
import {NavigationEvent} from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnInit{

  currentRoute: string;
  loadPage: boolean = true;

  isAuth: boolean;

  constructor(
      private titleService:Title,
      private authService:AuthService,
      private router: Router,
      private route: ActivatedRoute,
      private headerService: HeaderService,
      private themeService: ThemeService,
  ) {
    this.titleService.setTitle(environment.appTitle);
  }

  ngOnInit() {

    this.themeService.setTheme();

    this.authService.autoLogin();


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(event.url.split('/')?.length > 2) {
          localStorage.setItem('redirectUrl', event.url);
        }
      }
    });

    this.loadPage = false;
    this.isAuth = false;

  }




}

