import {Injectable} from "@angular/core";
import {
    HttpHandler, HttpHeaders,
    HttpInterceptor,
    HttpParams,
    HttpRequest
} from "@angular/common/http";

import {AuthService} from "./auth.service";
import {exhaustMap, take} from "rxjs";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {


    constructor(
        private authService: AuthService,
        private router:Router,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.authService.user.pipe(
            take(1),
            exhaustMap(user => {
                if(!user) {
                    return next.handle(req);
                }
                const modifiedReq = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${user.token}` },
                });
                return next.handle(modifiedReq);
            })
        );
    }
}
